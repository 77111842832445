.projects {
    display: flex;
    flex-direction: column;
    padding-block-start: 1rem;
    margin-block-start: -2rem;
}

.projects__title {
    font-size: var(--h1-font-size);
    font-family: "Adieu Regular";
    text-align: center;
    color: var(--black);
    padding-block-start: 4.5rem;
    padding-block-end: 2.5rem;
}