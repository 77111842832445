.case-studies {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 900px) {
    .case-studies {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
