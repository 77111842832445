.project-card {
    width: 100%;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: scale(1.02);
    text-decoration: underline;
}

.project-card__thumbnail {
    width: 100%;
    max-width: 540px;
    height: auto;
    aspect-ratio: 1.55 / 1; /* Use the average aspect ratio you provided */
    object-fit: cover;
}

.project-card__title {
    font-family: "Adieu Regular";
    font-size: var(--mid-copy-font-size);
    color: var(--black);
    text-decoration: none;
}
