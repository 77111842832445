.project-implementation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-end: 2.5rem;
}

.project-implementation__title {
    padding-block-start: 2.5rem;
    font-size: var(--h3-font-size);
    font-family: "Adieu Regular";
}

