.project-results {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-results__title {
    padding-block-start: 2.5rem;
    color: var(--dark-grey);
    font-size: var(--h3-font-size);
    font-family: "Adieu Regular";
}

.project-results__body {
    padding-inline: var(--project-copy-padding);
    font-size: var(--mid-copy-font-size);
    font-family: "Nexa Regular";
}