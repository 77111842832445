.process-phase {
    padding-inline-end: 1.5rem;
    display: flex;
    flex-direction: column;
}

.process-phase__name{
    order: 1;
    font-family: "Adieu Regular";
    font-size: var(--h5-font-size);
}

.process-phase__logo-box{
    order: 2;
    padding-block: 2rem
}

.process-phase__description{
    order: 3;
    font-family: "Nexa Regular";
    font-size: var(--mid-copy-font-size);
    color: var(--black);
    max-inline-size: 95%;
}

@media screen and (max-width: 900px) {
    .process-phase {
        padding-inline: 1rem;
    }
    .process-phase__name{
        order: 2;
    }

    .process-phase__logo-box{
        order: 1;
    }
}