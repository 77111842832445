.case-study {
    display: flex;
    height: auto;
    flex-direction: column;
    padding-inline: 1rem;
    padding-block: 1.5rem;
    font-family: "Adieu Regular";
    justify-content: space-between;
    text-decoration: none;
}

.case-study:hover {
    text-decoration: underline;
}

.case-study__topline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.case-study__title {
    font-size: var(--h2-font-size);
}

.case-study__image {
    width: 100%;
    display: block;
    height: auto;
    aspect-ratio: 1.55 / 1; /* Use the average aspect ratio you provided */
    object-fit: cover;
}

.case-study__description {
    padding-block: 1rem;
    margin-block-end: auto;
    font-size: var(--mid-copy-font-size);
}

@media screen and (min-width: 900px) {
    .case-study {
        inline-size: 33.33%;
        min-height: 100vh;
        padding-inline: 1rem;
        padding-block: 1rem;
    }

    .case-study__title {
        font-size: var(--h4-font-size);
    }

    .case-study__description {
        font-size: var(--mid-copy-font-size);
        padding-block-start: 1.5rem;
    }

    .case-study__image {
        margin-block-start: 9.5rem;
    }
}