.project-description__title {
    font-size: var(--h1-font-size);
    font-family: "Adieu Regular";
    text-align: center;
    padding-block-start: 4.5rem;
}

.project-description__summary {
    font-size: var(--h5-font-size);
    font-family: "Nexa Regular";
    text-align: center;
    padding-block: 2rem;
    padding-inline: 5rem;
}

.project-description__logo-box {
    display: flex;
    justify-content: center;
    padding-block-start: 2rem;
    padding-block-end: 3.5rem;
}

.project-description__images-box {
    display: flex;
    justify-content: center;
    padding-block-start: 2rem;
    padding-block-end: 2rem;
}

@media screen and (max-width: 600px) {
    .project-description__summary {
        padding-inline: 3.5rem;
    }
}

@media screen and (max-width: 430px) {
    .project-description__summary {
        padding-inline: 2rem;
    }
}