.footer {
    display: flex;
    flex-direction: column;
    padding-block: 0rem;
}

.footer__top {
    padding-block-start: 5.5rem;
    padding-block-end: 4rem;
}

.footer__logo-box {
    display: flex;
    justify-content: center;
    padding-block-end: 5.5rem;
}

.footer__cta {
    font-size: var(--h2-font-size);
    font-family: "Adieu Regular";
    text-align: center;
    color: var(--black);
    background-color: var(--light-grey);
    max-inline-size: 96%;
}

.footer__bottom {
    font-family: "Nexa Regular";
    padding-block-start: 3rem;
    padding-block-end: 4rem;
    padding-inline: 1.5rem;
}

.footer__about {
    font-size: var(--h4-font-size);
    padding-block-end: 2rem;
    color: var(--pink);
}

.footer__contact-info {
    font-size: var(--small-copy-font-size);
    padding-block-end: 2rem;
}

.footer__copyright {
    font-size: var(--small-copy-font-size);
}