.expertise {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;
}

.expertise__title {
    padding-block-start: 2rem;
    font-size: var(--h1-font-size);
    font-family: "Adieu Regular";
    text-align: center;
    color: var(--light-grey);
}