.project-problem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-problem__title {
    color: var(--light-grey);
    font-size: var(--h3-font-size);
    font-family: "Adieu Regular";
}

.project-problem__description {
    padding-inline: var(--project-copy-padding);
    font-size: var(--mid-copy-font-size);
    font-family: "Nexa Regular";
    color: var(--light-grey);
}

.project-problem-divider {
    width: 100%;
    height: 100%;
    margin-block-start: 1.5rem;
    max-height: 720px;
    background-size: contain; /* Fit the image to the container while maintaining aspect ratio */
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

.project-problem-divider__image {
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}

@media screen and (min-width: 900px) {
    .project-problem__description {
        padding-inline: var(--project-copy-padding);
    }
}

@media screen and (max-width: 600px) {
    .project-problem__description {
        padding-inline: var(--project-copy-padding);
    }
}

@media screen and (max-width: 430px) {
    .project-problem__description {
        padding-inline: var(--project-copy-padding);
    }
}