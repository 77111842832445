.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-block-size: 100vh;
    margin-block-start: -2rem;
}

.hero__background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.hero__copy {
    position: absolute;
    bottom: 2.5rem;
    padding-inline: 2.5rem;
    font-family: "Adieu Regular";
    font-size: var(--h4-font-size);
    text-align: center;
    max-inline-size: 95%;
}
