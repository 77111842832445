.projects-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-inline: 5rem;
    margin-block-end: 2rem;
}

.projects-nav-card {
    margin-inline: 0.5rem;
    max-width: 530px;
    height: auto;
    aspect-ratio: var(--aspect-ratio-default);
    object-fit: cover;
}

.projects-nav-card__link {
    display: block;
    font-family: "Adieu Regular";
    font-size: var(--mid-copy-font-size);
    color: var(--black);
    text-decoration: none;
    margin-block-end: 3rem;
}

.projects-nav-card__link:hover {
    font-size: var(--h5-font-size);
}

.projects-nav-card__title {
    font-size: var(--h4-font-size);

}

@media (max-width: 900px) {
    .projects-grid {
        flex-direction: row;
    }
}

@media screen and (max-width: 600px) {
    .projects-nav {
        margin-inline: 3rem;
        margin-block-start: 3.5rem;
    }
}

@media screen and (max-width: 430px) {
    .projects-nav {
        margin-inline: 1rem;
        margin-block-start: 2rem;
    }
}