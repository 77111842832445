.expertise-card__marker {
    color: var(--mid-grey);
    font-family: "Adieu Regular";
    font-size: var(--small-copy-font-size);
}

.expertise-card__title {
    color: var(--light-grey);
    font-size: var(--h4-font-size); /* clamp here */
    font-family: "Adieu Regular";
    padding-block: 0rem;
}

.expertise-card__description {
    padding-block-end: 2rem;
    color: var(--light-grey);
    font-family: "Nexa Regular";
    font-size: var(--mid-copy-font-size);
}