:root {
    --project-copy-padding: 2.5rem;
}

@media screen and (min-width: 600px) {
    :root {
        --project-copy-padding: 5rem;
    }
}

.project {
    display: flex;
    flex-direction: column;
    padding-block-start: 1rem;
    background-color: var(--dark-grey);
    margin-block-start: -2rem;
}