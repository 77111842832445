.expertise-stack {
    max-inline-size: 55%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-inline-start: 10rem;
    padding-block-start: 0rem;
}

@media screen and (max-width: 900px) {
    .expertise-stack {
        max-inline-size: 100%;
        padding-inline: 1rem;
        padding-block-start: 2rem;
    }

    /* Center the items in the expertise-stack */
    .expertise-stack > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

}