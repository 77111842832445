.navbar {
    display: flex;
    position: sticky;
    top: 2.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 2rem;
    z-index: 1000;
}

.navbar__link {
    font-family: "Adieu Regular";
    font-weight: normal;
    font-size: var(--small-copy-font-size);
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar__link--emphasized {
    font-family: "Adieu Regular";
    font-size: var(--big-copy-font-size);
}

.navbar__link:hover {
    font-family: "Adieu Regular";
    text-decoration: underline;
}

@media screen and (min-width: 900px) {
    .navbar {
        padding-inline: 3.5rem;
    }
}

@media screen and (max-width: 430px) {
    .navbar {
        padding-inline: 0.5rem;
    }
}