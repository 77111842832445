.culture {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block-start: 1rem;
    padding-block-end: 4.5rem;
    margin-block-start: -2rem;
}

.culture__title {
    font-size: var(--h1-font-size);
    font-family: "Adieu Regular";
    text-align: center;
    color: var(--pink);
    padding-block-start: 4.5rem;
    padding-block-end: 2.5rem;
}

.culture__headline {
    font-size: calc(4rem  * var(--mid-multiplier));
    font-family: "Nexa Regular";
    text-align: center;
    color: var(--light-grey);
    padding-block-end: 2.5rem;
    padding-inline: 3.5rem;
}

.culture__copy {
    margin-block-start: 2.5rem;
    font-size: var(--mid-copy-font-size);
    font-family: "Nexa Regular";
    color: var(--light-grey);
    padding-inline: var(--project-copy-padding);
}

@media screen and (min-width: 901px) {
    .culture__headline {
        max-inline-size: 95%;
    }

    .culture__copy {
        margin-block-start: 3rem;
        font-size: var(--mid-copy-font-size);
    }
}

@media screen and (max-width: 430px) {
    .culture__headline {
        padding-inline: 1.5rem;
    }
}