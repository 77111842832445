.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns in desktop view */
    grid-gap: 80px;
    align-self: center;
    max-width: 80%;
}

@media (max-width: 900px) {
    .projects-grid {
        grid-template-columns: 1fr; /* 1 column in mobile view */
        grid-gap: 40px;
    }
}
