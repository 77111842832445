.culture-team-member {
    padding: 0 1rem 0 1rem;
}

.culture-team-member__wrapper {
    font-size: var(--h5-font-size);
}

.culture-team-member__name {
    color: var(--light-grey);
    padding-inline-end: 1rem;
    display: inline;
}

.culture-team-member__role {
    display: inline;
    color: var(--pink);
}

.culture-team-member__thumbnail {
    width: 100%;
    max-width: 440px;
    height: auto;
    aspect-ratio: 0.71 / 1;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .culture-team-member__role {
        font-size: var(--h5-font-size);
    }

    .culture-team-member__name {
        font-size: var(--h5-font-size);
    }
}