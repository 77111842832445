/* TYPOGRAPHY - START */
@font-face {
    font-family: 'Adieu Regular';
    src: url('../../assets/fonts/Adieu-Regular.ttf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Regular';
    src: url('../../assets/fonts/Nexa-Regular.ttf') format('opentype');
    font-style: normal;
}

:root {
    --multiplier: 1;
    --small-multiplier: 1;
    --mid-multiplier: 1;
    --h1-font-size: calc(11.25rem * var(--multiplier)); /* 180px */
    --h2-font-size: calc(8.75rem * var(--multiplier)); /* 140px */
    --h3-font-size: calc(6.25rem * var(--multiplier)); /* 100px */
    --h4-font-size: calc(3rem * var(--mid-multiplier)); /* 48px */
    --h5-font-size: calc(2rem * var(--small-multiplier)); /* 32px */
    --big-copy-font-size: calc(1.625rem * var(--small-multiplier)); /* 26px */
    --mid-copy-font-size: calc(1.5rem * var(--small-multiplier)); /* 24px */
    --small-copy-font-size: calc(1.125rem * var(--small-multiplier)); /* 18px */
    --aspect-ratio-default: 1.44 / 1;
}

@media screen and (min-width: 1537px) {
    :root {
        --multiplier: 1.2;
        --mid-multiplier: 1.2;
        --small-multiplier: 1.05;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1536px) {
    :root {
        --multiplier: 0.9;
        --mid-multiplier: 0.9;
        --small-multiplier: 0.9;
    }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
    :root {
        --multiplier: 0.7;
        --mid-multiplier: 0.7;
        --small-multiplier: 0.95;
    }
}

@media screen and (min-width: 751px) and (max-width: 900px) {
    :root {
        --multiplier: 0.5;
        --mid-multiplier: 0.55;
        --small-multiplier: 0.9;
    }
}

@media screen and (min-width: 601px) and (max-width: 750px) {
    :root {
        --multiplier: 0.45;
        --mid-multiplier: 0.55;
        --small-multiplier: 0.9;
    }
}

@media screen and (min-width: 401px) and (max-width: 600px) {
    :root {
        --multiplier: 0.32;
        --mid-multiplier: 0.55;
        --small-multiplier: 0.85;
    }
}

@media screen and (max-width: 430px) {
    :root {
        --multiplier: 0.25;
        --mid-multiplier: 0.5;
        --small-multiplier: 0.8;
    }
}
/* TYPOGRAPHY - END */

/* COLORS - START */
:root {
    --white: #FFFFFF;
    --black: #000000;
    --light-grey: #E4E1E2;
    --mid-grey: #5D5D5D;
    --dark-grey: #2F2F2F;
    --pink: #E2C2D0;
}

.section--dark {
    background-color: var(--dark-grey);
    color: var(--light-grey);
}

.section--light {
    background-color: var(--light-grey);
    color: var(--dark-grey);
}

.section--pink {
    background-color: var(--pink);
    color: var(--dark-grey);
}
/* COLORS - END */