.process-stack {
    padding-inline-start: 2rem;
    padding-block-start: 2rem;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 900px) {
    .process-stack {
        padding-inline-start: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
